<template>
  <div class='agreement_container' ref='list'>
    <div class='agreement_main' ref='signPage'>
        <h3>{{title}}</h3>
        <p class='agreement_space_row'><span>甲方：</span><span class='agreement_main_line'>北京生命绿洲公益服务中心</span></p>
        <!-- <p class='agreement_space_row'><span>注册地址：</span><span class='agreement_main_line'>北京市东城区藏经馆胡同17号1幢1863室</span></p> -->
        <p class='agreement_space_row'><span>乙方：</span><span class='agreement_main_line'>{{items.doctor.doctor_name}}</span></p>
        <p class='agreement_space_row'><span>身份证号码：</span><span class='agreement_main_line'>{{items.doctor.doctor_id_card}}</span></p>
        <p class='agreement_space_row'><span>手机号（必填）</span><span class='agreement_main_line'>{{items.doctor.doctor_phone}}</span></p>
        <p >为明确双方义务及权利，经双方协商达成以下劳务协议:</p>
        <p class='agreement_summary'>期限为自协议签署之日起至项目结束终止</p>
        <div class='agreement_table'>
          <div class="agreement_row">
              <div class="agreement_td">服务时间</div>
              <div class="agreement_td">{{getText(0)}}年{{getText(1)}}月{{getText(2)}}日</div>
          </div>
          <div class="agreement_row">
              <div class="agreement_td">活动名称</div>
              <div class="agreement_td">{{items.meet.meeting_name}}</div>
          </div>
          <div class="agreement_row">
              <div class="agreement_td">服务类别</div>
              <div class="agreement_td">学术会议主持</div>
          </div>
          <div class="agreement_row">
              <div class="agreement_td">服务内容</div>
              <div class="agreement_td">IO智能云课堂会议主持</div>
          </div>
          <div class="agreement_row">
              <div class="agreement_td">服务报酬</div>
              <div class="agreement_td">人民币1500元（税后）</div>
          </div>
          <div class="agreement_row">
              <div class="agreement_td">开户行信息</div>
              <div class="agreement_td"><div>{{items.doctor.deposit_bank}}</div><div>{{items.doctor.bank_account}}</div></div>
          </div>
        </div>
        <h4>第一条	双方的基本权利与义务</h4>
        <p>1.	乙方许可甲方依据本协议约定使用其肖像，并同意甲方拥有对带有乙方肖像的作品的所有权益（包括但不限于所有权和知识产权）</p>
        <p>2.	甲方应当依据本协议约定的用途妥善使用乙方肖像。</p>
        <p>3.	如乙方严重违反劳动纪律或机构规章制度，甲方有权终止协议。乙方的行为给甲方造成损失的，由乙方赔偿，情节严重的追究法律责任；</p>
        <p>4.	乙方由于个人原因所发生的疾病以及伤残等意外事故，乙方自行负责； </p>
        <p>5.	甲、乙任何一方单方面解除本协议的，需以书面通知告知另一方，经双方协商终止本协议；</p>
        <p>6.	双方应严格保守在合作过程中所了解的商业及技术机密，否则应对因此造成的损失承担赔偿；</p>
        <p>7.	乙方将向甲方提供其身份证复印件，银行卡复印件以及银行大额支付号（12位），以便甲方依法进行付款和纳税申报。</p>
        <h4>第二条	知识产权</h4>
        <p>1.	甲方或其聘用的第三方为乙方提供服务进行拍摄、录音（如涉及）或记录整理而获得的视频、音频或记录资料，甲方对此享有知识产权。甲方有权对该等视频、音频、资料进行剪辑、编辑、出版、发行等。为免疑义，出版包括在社交网络、移动应用程序、网页以及任何程序或在线工具的在线出版；</p>
        <p>2.	甲方对乙方提供的与本协议项下服务有关的资料，包括但不限于文件、PPT等，享有非排他使用权。乙方承诺其提供的该等资料不会侵犯任何第三方的知识产权、隐私权等权利；</p>
        <p>3.	乙方承诺其对本协议的签署及履行在任何意义上都不违反适用法律及其所执业医疗机构的内部制度，乙方提供本协议项下服务（包括视频及音频的录制等）已获得其所执业医疗机构及相关政府部门（如涉及）的许可；</p>
        <p>4.	若服务涉及视频及音频的录制或其他关乎第三人的情形时，乙方进一步承诺已获得相关被拍摄人员或其他第三人的同意。   </p>
        <h4>第三条	发生下列情形之一，本协议解除终止：</h4>
        <p>1.	双方就解除本协议协商一致的；</p>
        <p>2.	乙方不能胜任工作或弄虚作假不符合录用条件的；</p>
        <p>3.	因本协议签署时依据的客观情况发生重大变化，致使本协议无法履行的。</p>
        <h4>第四条	本协议解除或终止时，乙方应下列义务：</h4>
        <p>1.	向甲方指定的人交接工作；</p>
        <p>2.	乙方应立即停止以甲方名义从事的一切活动；</p>
        <p>3.	交接清晰一些与工作相关的事务。</p>
        <h4>第五条	法律适用和争议解决</h4>
        <p>1.	本协议适用中华人民共和国的相关法律。</p>
        <p>2.	所有因本协议引起的或与本协议有关的任何争议将通过双方友好协商解决。如果双方不能通过友好协商解决争议，则任何一方均可将该争议提交北京仲裁委员会申请仲裁。</p>
        <h4>第六条	本协议如有未尽事宜，双方本着友好协商原则处理。</h4>
        <h4>第七条	 本协议一式两份，甲、乙双方各执一份，具有同等效力，经甲方盖章、乙方签字后生效。</h4>
        <div class='sign_group'>
          <div class="sign_block">
            <div class="sign_block_content">
              <div class="sign_key" >甲方：</div>
              <div class="sign_value">北京生命绿洲公益服务中心</div>
            </div>
            <div class="sign_block_content">
              {{getText(0)}}&nbsp;年&nbsp;{{getText(1)}}&nbsp;月&nbsp;{{getText(2)}}&nbsp;日
            </div>
          </div>
          <div class="sign_block">
            <div class="sign_block_content">
              <div class="sign_key" >乙方：</div>
              <div class="sign_value">
                <el-button  v-if='!data.img_sign && !isSigned' type="primary" size='small' @click.native='startSignName'>签名</el-button>
                <img  v-if='data.img_sign' :src="data.img_sign" alt="" @load='uploadPage' crossorigin="anonymous">
                <!-- <img  v-if='data.img_sign' :src="require('@/assets/images/signname.png')" alt="" @load='uploadPage' crossorigin='Anonymous'> -->
              </div>
            </div>
            <div class="sign_block_content">
              {{getText(0)}}&nbsp;年&nbsp;{{getText(1)}}&nbsp;月&nbsp;{{getText(2)}}&nbsp;日
            </div>
          </div>
        </div>
          
        <div class='agreement_btn_group' v-if='!isSigned && !isUpload'>
            <el-button type="primary" @click.native='doCommit'>确认提交</el-button>
        </div>
    </div>
    <div class='sign_name_block' v-show='showStatement'>
      <div class='sign_name_bg' ></div>
      <div class='agreement_headimg_main' @click.stop='setStatement(false)'>
        <div class='agreement_headimg_block' @click.stop=''>
          <h4 class="agreement_headimg_title">声 明</h4>
          <div class="agreement_headimg_content">
            <p class='agreement_summary'>本人受北京生命绿洲公益服务中心委托，担任IO智能云课堂会议主持。</p>
            <!-- <h4>一、 肖像权认定</h4> -->
            <p class='agreement_summary'>在此，本人同意北京生命绿洲公益服务中心依据本协议约定使用本人肖像，并同意北京生命绿洲公益服务中心拥有上述带有我肖像的作品的所有权和知识产权等所有权益。 </p>
            <p class='agreement_summary'>本次签名只用于本协议。</p>
            <p class='agreement_summary'>特此声明。 </p>
          </div>
          <div class="agreement_headimg_btngroup">
            <div>
                <el-button type="info" size='small' @click.stop='setStatement(false)' round>不同意</el-button>
            </div>
            <div>
                <el-button type="primary" size='small'  @click.stop='setStatement(true)' round>同意</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='sign_name_block' v-show='!isSigned && showTips'>
      <div class='sign_name_bg' ></div>
      <div class='agreement_headimg_main'>
        <div class='agreement_headimg_block' @click.stop=''>
          <h4 class="agreement_headimg_title">提 示</h4>
          <div class="agreement_headimg_content">
            <p class='agreement_summary'>请核对个人信息，并滑动到底部进行签名</p>
          </div>
          <div class="agreement_headimg_btngroup">
            <!-- <div>
                <el-button type="info" size='small' @click.stop='setStatement(false)' round>不同意</el-button>
            </div> -->
            <div>
                <el-button type="primary" size='small'  @click.stop='showTips = false' round>同意</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='sign_name_block' v-show='showSignName'>
      <div class='sign_name_bg'  @click='closeSignName' ></div>
      <div class='agreement_autograph'>
            <div class='agreement_autograph_back'>
                <div class='agreement_autograph_text'>签名</div>
                <div class='agreement_autograph_row agreement_autograph_row_line'>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                </div>
                <div class='agreement_autograph_row agreement_autograph_row_line'>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                </div>
                <div class='agreement_autograph_row '>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                    <div class='agreement_autograph_col'></div>
                </div>
                <div class='agreement_autograph_content'>
                  <canvas @touchstart.stop="touchstart"  @touchmove.stop="touchmove" @touchend.stop="touchend" @touchcancel.stop="touchcancel" ref='canvas'></canvas>
                </div>
            </div>
            <div class='agreement_btn_sign_group' >
              <div>
                <el-button type="info" size='small' @click.stop='clearSignName'>清空</el-button>
                <el-button type="primary" size='small' @click.stop='takeSignName'>确认</el-button>
              </div>
            </div>
        </div>
    </div>
    <div class='agreement_loading_block' v-if='isUpload'>
      <div class="agreement_loading_bg"></div>
      <div class='agreement_loading_content'>
        <i class='el-icon-loading'></i>
        <p>协议正在保存中，请不要关闭页面</p>
      </div>
    </div>
  </div>
</template>

<script>
import BSscroll from 'better-scroll'
import upload from '@/common/uploads'
export default {
  name:'agreement',
  data(){
    return {
      showStatement:false,
      showSignName:false,
      config:{
      },   
      drawCanvas:{
        x:0,
        y:0
      },
      canvas:null,
      context:null,
      isAgree:false,
      data:{
        meet_id:'',
        img_sign:'',
        img_page:''
      },
      items:{
        doctor:{},
        meet:{}
      },
      showTips:true,
      isSigned:true,
      isUpload:false,
      title:"劳务协议"
    }
  },
  created(){
    document.getElementsByTagName('title')[0].innerHTML = this.title
    this.data.meet_id = this.$route.query.meet_id;
    this.curDate = this.$tools.getDate()
    this.loadData();
  },
  mounted(){
    this.initCanvas();
  },
  methods:{
    getText(index){
      return this.curDate.split(/[\s-:]/g)[index] || '';
    },
    loadInfo(){

    },
    loadData(){
      let url = this.$tools.getURL(this.$urls.doctor.sign,{meet_id:this.data.meet_id})
      this.$axios.get(url).then((res)=>{
        if(res.errcode){
          wxInfo.wx.closeWindow();
          return;
        }
        let data = res.data;
        this.items.doctor = res.doctor
        this.items.meet = res.meet
        if(data){
          this.data.img_sign = data.img_sign;
          this.data.img_page = data.img_page;
          this.curDate = data.sign_date
        }
        this.isSigned = !!res.data;
        this.initScroll()
      })
    },
    initScroll(){
      if(!this.scroll){
        this.$nextTick(()=>{
         this.scroll = new BSscroll(this.$refs.list,{
            click:true,
            bounce:false
          })
        })
      }else{
        this.scroll.refresh();
      }
    },
    setStatement(status){
      if(status == true) this.isAgree = true;
      this.showStatement = false;
      this.showSignName = status;
    },
    startSignName(){
      if(this.isAgree) {
        this.showSignName = true;
        return;
      }
      this.showStatement = true;
    },
    closeSignName(){
      // this.clearSignName();
      this.showSignName = false;
    },
    clearSignName(){
      this.context.clearRect(0,0,this.canvas.width + this.canvas.offsetLeft,this.canvas.width + this.canvas.offsetTop)
    },
    async takeSignName(){
      this.isUpload = true;
      let file = this.canvas.toDataURL("image/png");
      this.data.img_sign = await upload.QuestFile(file,'file',this.$urls.uploadBase64);
      this.showSignName = false;
      // 暂时不要全图截屏
      this.isUpload = false;
    },
    async uploadPage(){
      // 暂时不要全图截屏
      return;
      if(this.isSigned) return;
      this.data.img_page = await upload.savePage(this.$refs.signPage)
      this.isUpload = false;
      // this.$tips.success({text:'保存成功'})
    },
    initCanvas(){
      this.$nextTick(()=>{
        this.canvas =  this.$refs.canvas;
        this.context =  this.canvas.getContext("2d");
      })
    },
    touchstart(event){
      let dom = event.touches[0];
      event.preventDefault()
      let position = this.canvas.getBoundingClientRect();
      this.drawCanvas = {
        x:dom.clientX - this.canvas.offsetLeft - position.left,
        y:dom.clientY - this.canvas.offsetTop - position.top
      }
      this.scroll.disable()
    },
    touchmove(event){
      event.preventDefault()
      if(this.start_time > Date.now()-10) return;
      this.start_time = Date.now() + 10;
      let dom = event.touches[0];
      let position = this.canvas.getBoundingClientRect();
      let target = {
        x:dom.clientX - this.canvas.offsetLeft - position.left,
        y:dom.clientY - this.canvas.offsetTop - position.top
      }
      // target = {
      //   x:target.x-(target.x-this.drawCanvas.x),
      //   y:target.y-(target.y-this.drawCanvas.y)
      // }
      this.$nextTick(()=>{
        this.drawSignName(target)
        this.drawCanvas = {...target};
      })
    },
    touchend(event){
      this.initPoint();
      this.scroll.enable()
    },
    touchcancel(event){
      this.initPoint();
      this.scroll.enable()
    },
    initPoint(){
      // this.drawCanvas = {
      //   x:0,
      //   y:0
      // }
    },
    drawSignName(target){
      this.context.beginPath();
      this.context.globalAlpha = 1;
      this.context.lineWidth = 2;
      this.context.strokeStyle = "#000";
      let xRation = this.canvas.width/this.canvas.clientWidth;
      let yRation = this.canvas.height/this.canvas.clientHeight;
      this.context.moveTo(this.drawCanvas.x * xRation, this.drawCanvas.y *yRation);
      this.context.lineTo(target.x * xRation, target.y *yRation);
      this.context.closePath();
      this.context.stroke();
    },
    checkData(){
        if(this.data.img_sign == ''){
          this.$tips.error({text:'请先签名'})
          return false;
        }
        // if(this.data.img_page == ''){
        //   // this.$tips.error({text:'协议正在保存中，请不要关闭页面'})
        //   return false;
        // }
        return true;
    },
    doCommit(){
      if(!this.checkData() || this.isLoading || this.isSigned) return;
      this.isLoading = true;
      this.$axios.post(this.$urls.doctor.sign,this.data).then((res)=>{
        setTimeout(()=>{ this.isLoading = false;},1000)
        if(res.errcode) return;
        this.isSigned = true;
        wxInfo.wx.closeWindow()
      })
    },
  },
}
</script>
<style>
  .agreement_container{
    height:100%;
    overflow: hidden;
    /* padding:0 5.6vw; */
    background:#fff;
  }
  .agreement_main{
    padding:5.6vw;
  }
  .agreement_main h3{
    text-align: center;
    padding-bottom:4vw;
  }
  .agreement_title{
    font-size:4.2vw;
    text-align: center;
    margin-bottom:2.8vw;
  }
  .agreement_container p{
    font-size:3.6vw;
    margin-bottom:2.8vw;
    color:#333;
    line-height: 6.06vw;
  }
  .agreement_container h4{
    margin:4vw 0 2vw;
  }
  .agreement_summary{
    text-indent: 7.2vw;
    word-break: break-all;
  }
  .agreement_list{
    padding-left: 9vw;
  }
  .agreement_autograph{
      width:100%;
      padding:0 2vw;
      height:81vw;
      display:flex;
      justify-content:center;
      background:#fff;
      flex-direction: column;
      position: relative;
      z-index:2;
  }
  .agreement_autograph_back{
    width:96vw;
    height:62vw;
    border:1px solid #111;
    position:relative;
  }
  .agreement_autograph_row{
      display:flex;
      box-sizing: border-box;
  }
  .agreement_autograph_row_line{
      border-bottom:1px dashed #e9e9e9;
  }
  .agreement_autograph_col{
      height:20vw;
      width:25%;
      background:#fff;
      border-right:1px dashed #e9e9e9;
  }
  .agreement_autograph_row>.agreement_autograph_col:last-child{
      border:none;
  }
  
  .agreement_btn_group{
    width:100%;
    margin-top:5vw;
  }
  .agreement_btn_group button{
    width:100%;
  }
  .agreement_btn_sign_group{
    flex:20vw;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    z-index:999;

  }
  .agreement_btn_sign_group button{
    width:24vw;
  }
  .agreement_autograph_text{
      position:absolute;
      width:100%;
      height:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      font-size:30vw;
      color:#e6e6e6;
      opacity:.5;
      /* letter-spacing: 5vw; */
      
  }
  .agreement_main_line{
    display:inline-block;
    border-bottom:1px solid #111;
    min-width:40vw;
  }
  .agreement_main_line button{
    width:20vw;
    height:6vw;
    line-height: 6vw;
    padding-top:0;
    padding-bottom:0;
    margin:1vw 0;
  }
  .agreement_main_line img{
    height:6vw;
    width:auto;
  
  }
  .sign_name_block{
    height:100%;
    width:100%;
    position: absolute;
    left:0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sign_name_bg{
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
    background:#f1f1f1;
    opacity: .5;
  }
  .sign_name_bg_content{
    position: absolute;
    left:0;
    top:0;
    z-index:3;
    height:100%;
    width:100%;
  }
  .agreement_autograph_content{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agreement_headimg_main{
    position: relative;
    z-index:100;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0 9vw;
  }
  .agreement_headimg_main .sign_name_bg{
    opacity: .8;
  }
  .agreement_headimg_block{
    background:#fff;
    height:auto;;
    max-height:120vw; 
    border-radius:2vw;
    padding:0 8vw;
    display: flex;
    flex-direction: column;
  }
  .agreement_headimg_content{
    flex:1 0 0;
  }
  .agreement_headimg_btngroup{
    flex:16vw 0 0 ;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .agreement_headimg_btngroup div{
    flex:1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agreement_headimg_btngroup button{
    width:20vw;
  }
  .agreement_headimg_title{
    flex:10vw 0 0;
    line-height: 10vw;;
    text-align: center;
  }
  .agreement_autograph_content img{
    width:80%;
    height:auto;
  }
  .agreement_autograph_content canvas{
    width:100%;
    height:100%;
  }
  .sign_group{
    display: flex;
    height:26vw;
  }
  .sign_block{
    flex:1 0 0;
    display: flex;
    flex-direction: column;
  }
  .sign_block:first-child{
    padding-right:4vw;
  }
  .sign_block_content{
    flex:1 0 0;
    display: flex;
    align-items: center;
  }
  .sign_value{
    flex:1 0 0;
  }
  .sign_block_content button{
    width:24vw;
  }
  .sign_block_content img{
    height:12vw;
    width:auto;
  }
  .agreement_bottom_line{
    text-decoration:underline
  }
  .agreement_space_row{
    display: flex;
    padding-right:4vw;
  }
  .agreement_space_row span:first-child{
    /* flex:22vw 0 0; */
  }
  .agreement_space_row span:last-child{
    flex:1 0 0;
    border-bottom:1px solid #000;
  }
  .agreement_loading_block,.agreement_loading_bg{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:9999;
  }
  .agreement_loading_block{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agreement_loading_block i{
  }
  .agreement_loading_content{
    position: relative;
    z-index:2;
    color:#fff;
    text-align: center;
    font-size:15vw;
    z-index:99999;
  }
  .agreement_loading_content p{
    font-size:5vw;
    color:#fff;
  }
  .agreement_loading_bg{
    z-index:1;
    background:gray;
    opacity: .7;
  }
  .agreement_table{
    width:100%;
    height:100%;
    border:1px solid #000;
  }
  .agreement_row{
    display: flex;
    min-height:6vw;
  }
  .agreement_td{
    padding:1vw 2vw;
    line-height: 6vw;
    border-bottom:1px solid #000;
    border-right:1px solid #000;
    word-break: break-all;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .agreement_td div{
    line-height: 4.8vw;
  }
  .agreement_row .agreement_td:first-child{
    flex:25vw 0 0;
  }
  .agreement_row .agreement_td:last-child{
    flex:1 0 0;
    border-right:0;
  }
  .agreement_row:last-child>.agreement_td{
    border-bottom:0;
  }
</style>